import getAlignment from "utils/getAlignment";

export default ({ candidateInTeam: candidate, viewer }) => {
  const list = [];

  list.push({
    bigTitle: "Position requirements",
    description:
      "Use this section to understand and evaluate whether the candidate has the right experience, qualifications, and skills required to achieve the key objectives of the role.",
    color: "primary",
    items: [],
  });

  const evaluations = candidate.evaluation.filter(
    (e) => e.evaluatedBy.id === viewer.id
  );

  candidate.position.requirements.map((req) => {
    const ev = evaluations.find(
      (e) => e.item_id === req.id && e.type === "requirement"
    );

    list[
      list.findIndex((t) => t.bigTitle === "Position requirements")
    ].items.push({
      id: ev && ev.id,
      type: "Position requirements",
      resource: "requirements",
      evType: "requirement",
      item_id: req.id,
      color: "primary",
      title: req.title,
      desc: req.description,
      evaluation: ev && ev.evaluation,
      comments: ev && ev.comments,
    });
  });

  list.push({
    bigTitle: "Competencies",
    description:
      "Evaluate whether the candidate has the leadership skills and competencies required to achieve superior performance in the role. Pay particular attention to must-have competencies and competencies where the candidate indicates lower confidence levels.",
    color: "green",
    items: [],
  });

  candidate.position.competenciesHaveBeenConfed &&
    candidate.position.competencies.slice(0, 3).map((comp) => {
      const ev = evaluations.find(
        (e) => e.item_id === comp.id && e.type === "competency"
      );

      list[list.findIndex((t) => t.bigTitle === "Competencies")].items.push({
        id: ev && ev.id,
        type: "Competencies",
        resource: "competencies",
        evType: "competency",
        item_id: comp.id,
        color: "green",
        dim: candidate.competencies
          ? candidate.competencies.find((cc) => cc.name === comp.title)
          : candidate.position.competencies.find(
              (cc) => cc.name === comp.title
            ),
        label: null,
        title: comp.title,
        desc: comp.desc,
        questions: candidate.competencies
          ? candidate.competencies
              .find((cc) => cc.name === comp.title)
              .questionsForCandidate.map((q) => q.replace(/, /g, " "))
          : comp.questionsForCandidate
          ? comp.questionsForCandidate.map((q) => q.replace(/, /g, " "))
          : null,
        evaluation: ev && ev.evaluation,
        comments: ev && ev.comments,
      });
    });

  list.push({
    bigTitle: "Team fit",
    color: "yellow",
    items: [],
  });

  const personalityDims = [];
  const personality = evaluations.find((e) => e.type === "personality");

  if (candidate.personality) {
    candidate.personality
      .filter((dim) => !dim.parentGroup)
      .map((p) => {
        const { label, colour } = getAlignment({
          dim: p,
          team: candidate.position.parentTeam,
          teamMember: candidate,
          resource: "personality",
        });

        personalityDims.push({
          dim: candidate.personality.find((cc) => cc.name === p.name),
          label,
          color: colour,
        });
      });
  }

  list[list.findIndex((t) => t.bigTitle === "Team fit")].items.push({
    id: personality && personality.id,
    type: "Personality",
    resource: "personality",
    evType: "personality",
    item_id: 3,
    color: "yellow",
    dims: personalityDims,
    title: "Trait diversity",
    desc: "Looking at the personality profile of the candidate, evaluate the level of desired behaviors and thinking-styles the candidate would add to the team.",
    candidate,
    team: candidate.position.parentTeam,
    label: null,
    questions: [],
    evaluation: personality && personality.evaluation,
    comments: personality && personality.comments,
  });

  const valuesDims = [];
  const values = evaluations.find((e) => e.type === "values");

  if (candidate.values) {
    const topValues = candidate.values
      .filter((dim) => dim.parentGroup)
      .sort((a, b) => b.score - a.score)
      .slice(0, 3)
      .forEach((p) => {
        const { label, colour } = getAlignment({
          dim: p,
          team: candidate.position.parentTeam,
          teamMember: candidate,
          resource: "values",
        });

        valuesDims.push({
          dim: candidate.values.find((cc) => cc.name === p.name),
          label,
          color: colour,
        });
      });

    candidate.values
      .filter((dim) => dim.parentGroup)
      .sort((a, b) => a.score - b.score)
      .slice(0, 3)
      .forEach((p) => {
        const { label, colour } = getAlignment({
          dim: p,
          team: candidate.position.parentTeam,
          teamMember: candidate,
          resource: "values",
        });

        valuesDims.push({
          dim: candidate.values.find((cc) => cc.name === p.name),
          label,
          color: colour,
        });
      });
  }

  list[list.findIndex((t) => t.bigTitle === "Team fit")].items.push({
    id: values && values.id,
    type: "Values",
    resource: "values",
    evType: "values",
    item_id: 4,
    color: "red",
    dims: valuesDims,
    title: "Values fit",
    desc: "Looking at the personality profile of the candidate, evaluate the level of desired behaviors and thinking-styles the candidate would add to the team.",
    candidate,
    team: candidate.position.parentTeam,
    label: null,
    questions: [],
    evaluation: values && values.evaluation,
    comments: values && values.comments,
  });

  const intuition = evaluations.find(
    (e) => e.type === "intuition" && e.item_id === 5
  );

  list[list.findIndex((t) => t.bigTitle === "Team fit")].items.push({
    id: intuition && intuition.id,
    type: "Gut feeling",
    resource: "intuition",
    evType: "intuition",
    color: "purple",
    item_id: 5,
    title: "Gut feeling",
    desc: "What is your gut feeling telling you about this candidate? Would they be a good fit for the role and the team?",
    questions: null,
    evaluation: intuition && intuition.evaluation,
    comments: intuition && intuition.comments,
  });

  return list;
};
